import React, { useContext } from "react";
import tw, { styled } from "twin.macro";
import { graphql } from "gatsby";
import { useDispatch } from "react-redux";
import { selectCategory } from "../../store/actions/resources";
import { useSelector } from "react-redux";
import { ThemeContext } from "../themes/theme-context";

import iconCategoryFeatured from "../../assets/icon-category-featured.svg";
import iconCategoryDistraction from "../../assets/icon-category-distraction.svg";
import iconCategoryConnection from "../../assets/icon-category-connection.svg";
import iconCategoryCoping from "../../assets/icon-category-coping.svg";
import iconCategoryCaringContacts from "../../assets/icon-category-caring-contacts.svg";
import iconCategoryArchivedResources from "../../assets/icon-category-archived-resources.svg";
import iconCategoryFeaturedActive from "../../assets/icon-category-featured-active.svg";
import iconCategoryDistractionActive from "../../assets/icon-category-distraction-active.svg";
import iconCategoryConnectionActive from "../../assets/icon-category-connection-active.svg";
import iconCategoryCopingActive from "../../assets/icon-category-coping-active.svg";
import iconCategoryCaringContactsActive from "../../assets/icon-category-caring-contacts-active.svg";
import iconCategoryArchivedResourcesActive from "../../assets/icon-category-archived-resources-active.svg";

const StyledResourceNav = tw.nav`grid grid-cols-5 mt-3 mb-3 gap-3 md:gap-5 lg:mt-0 lg:mb-6 lg:gap-7`;

const StyledButton = styled.button(({ gradient }) => [
	tw`flex flex-row rounded-full justify-center items-center shadow-resource-button font-medium text-sm leading-sans-sm font-sans text-black px-2 py-2 w-full lg:text-lg lg:leading-sans-lg lg:py-4 transition duration-150 ease-linear transform hover:scale-105`,
	gradient,
	tw`before:content before:rounded-full before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0  before:-z-2`,
]);

const StyledIcon = tw.div`w-5 mr-3 lg:w-6 xl:w-7 xl:mr-4`;

const featuredBackground = tw`before:bg-purple text-white`;
const distractionBackground = tw`before:bg-blue text-white`;
const connectionBackground = tw`before:bg-pink text-white`;
const copingToolsBackground = tw`before:bg-green text-white`;
const caringContactsBackground = tw`before:bg-purple-light text-white`;
const archivedResourcesBackground = tw`before:bg-teal text-white`;

const CategoryColors = {
	featured: featuredBackground,
	distraction: distractionBackground,
	connection: connectionBackground,
	"coping-tools": copingToolsBackground,
	"caring-contacts": caringContactsBackground,
	"archived-resources" : archivedResourcesBackground,
};

const CategoryIcons = {
	featured: iconCategoryFeatured,
	distraction: iconCategoryDistraction,
	connection: iconCategoryConnection,
	"coping-tools": iconCategoryCoping,
	"caring-contacts": iconCategoryCaringContacts,
	"archived-resources" : iconCategoryArchivedResources,
};

const CategoryIconsActive = {
	featured: iconCategoryFeaturedActive,
	distraction: iconCategoryDistractionActive,
	connection: iconCategoryConnectionActive,
	"coping-tools": iconCategoryCopingActive,
	"caring-contacts": iconCategoryCaringContactsActive,
	"archived-resources" : iconCategoryArchivedResourcesActive,
};

const ResourceCategories = ({ data, featuredCategory }) => {
	const selectedCategory = useSelector((state) =>
		state.resources.selectedCategory
			? state.resources.selectedCategory
			: featuredCategory[0]
	);

	// console.log(selectedCategory);

	const dispatch = useDispatch();
	const { theme, toggleTheme } = useContext(ThemeContext);

	const handleCategoryButton = (item) => {
		// console.log(item.title);
		toggleTheme(item.title);
		dispatch(selectCategory(item));
	};

	return (
		<StyledResourceNav>
			{data.nodes.map((item) => {
				// console.log(item.title);
				const categoryTag = item.title.toLowerCase().split(" ").join("-");

				const isFeatured = categoryTag === "featured";

				return (
					!isFeatured && (
						<StyledButton
							key={item.title}
							categoryId={item.id}
							onClick={(e) => handleCategoryButton(item)}
							gradient={theme.buttonLighter}
							css={
								item.id === selectedCategory.id
									? CategoryColors[categoryTag]
									: ""
							}
						>
							<StyledIcon tw="hidden md:block">
								{item.id === selectedCategory.id ? (
									<img
										tw="w-full h-full"
										src={CategoryIconsActive[categoryTag]}
										alt={item.title}
										aria-hidden="true"
									/>
								) : (
									<img
										tw="w-full h-full"
										src={CategoryIcons[categoryTag]}
										alt={item.title}
										aria-hidden="true"
									/>
								)}
							</StyledIcon>
							<div>{item.name}</div>
						</StyledButton>
					)
				);
			})}
		</StyledResourceNav>
	);
};

export const resourceCategoriesFragment = graphql`
	fragment resourceCategoriesFragment on ContentfulResourceCategory {
		id
		name
		title
		listViewSubheadline {
			raw
		}
		cardViewSubheadline {
			raw
		}
	}
`;

export default ResourceCategories;
